import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { EventListenerKeys, LocalStorageKeys, SeatStatus } from "../../../components/src/enums.web";
import { IBookingData, IBookingResponse, IPrice, IResellBookingResponse, ISeat, ISeatSelectionGridApiResponse, ISeatStructure, ISection, IUserDetails } from "../../../components/src/interfaces.web";
import { callApi } from "../../../components/src/Toolkit";
import { getLastPartOfURL } from "../../../components/src/utilities";

// Customizable Area Start
import { createRef } from "react";
interface ISelectedSeatData {
  seatSectionType: string
  seatStatus: SeatStatus
  seatNumber: string
  bookingId: number
}

interface IRequestBookingData {
  requestPrice: number,
  seatSectionType: string,
  sectionMinimumPrice: number,
  seatIds: number[],
  seatNumber: string[],
  walletBalance: number,
  sectionMinimumPriceErrorMessage: boolean
}

interface IWalletResponse {
  total_amount: number
}

interface IRequestBookingPayload {
  booking_request: {
    bookings: {
      booking_id: number,
      ticket_price: number,
      seat_ids: number[]
    }[],
    request_type: "buy" | "resell",
    screening_id: number
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  objMovie: any;
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  seats: any;
  price: any;
  seatGroups: any;
  isModalOpen: boolean,
  isGuest: boolean,
  singleSeatData: any,
  seatType: boolean,
  timeSlotList: {
    timeSlot: string;
    screenId: number;
  }[];
  screen: {
    screenId: number;
    screenName: string;
  },
  selected: boolean;
  selectedTime: number | null;
  date: any;
  movieName: any;
  movieId: number;
  selectedTimeId: number;
  selectedNumber: any;
  selectedSeats: any;
  seatTypeNumber: any;
  seatsSelected: any;
  isLoading: boolean;
  selectionType: string;
  tierSelected: string;
  isEdit: boolean;
  doSignUp: any;
  userDetails: any;
  bookingError: string;
  bookingValue: boolean;

  userToken: string;

  seatSelection: ISection[];
  isSeatGridLoading: boolean;
  selectedSeatIds: Map<number, ISelectedSeatData>;
  requestBookingData: IRequestBookingData;

  isPriceAlertOpen: boolean;
  isPricePerTicketOpen: boolean;
  isConfirmationForPriceOpen: boolean;
  isRequestPlacedOpen: boolean;
  isInsufficientBalanceOpen: boolean;
  isBackDropLoadingOpen: boolean;
  openBookingError: boolean;
  showKycDialog: boolean;
  notifyMeChecked: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
export default class SeatSelectionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getScreenCalledId: any
  getScreenCalled: any
  createBookingApiId: string = ''
  walletBalanceApiId: string = '';
  createRequestBookingApiId: string = '';
  createResellBookingApiId: string = '';
  getAccountDetailsCallId: string = "";
  notifyMeApiId: string = "";
  parentRef: React.RefObject<HTMLDivElement>;
  childRef: React.RefObject<HTMLDivElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseData),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      seats: [],
      price: [],
      seatGroups: {},
      isModalOpen: false,
      isGuest: false,
      singleSeatData: [],
      seatType: true,
      timeSlotList: [],
      screen: {
        screenId: 0,
        screenName: ""
      },
      selected: false,
      selectedTime: 0,
      date: "",
      movieName: "",
      movieId: 0,
      selectedTimeId: 0,
      selectedNumber: null,
      selectedSeats: [],
      seatTypeNumber: [
        {
          title: 'Solo',
          numberOfSeats: 1,
          isSelected: false
        },
        {
          title: 'Twin',
          numberOfSeats: 2,
          isSelected: true
        },
        {
          title: 'Trio',
          numberOfSeats: 3,
          isSelected: false
        },
        {
          title: 'Band',
          numberOfSeats: 4,
          isSelected: false
        },
        {
          title: 'Unit',
          numberOfSeats: 5,
          isSelected: false
        },
        {
          title: 'Crew',
          numberOfSeats: 6,
          isSelected: false
        },
        {
          title: 'Gang',
          numberOfSeats: 7,
          isSelected: false
        },
        {
          title: 'Club',
          numberOfSeats: 8,
          isSelected: false
        },
        {
          title: 'Team',
          numberOfSeats: 9,
          isSelected: false
        },
        {
          title: 'Army',
          numberOfSeats: 10,
          isSelected: false
        },
      ],
      seatsSelected: [],
      isLoading: false,
      selectionType: '',
      tierSelected: "",
      isEdit: false,
      doSignUp: false,
      userDetails: {},
      bookingError: '',
      bookingValue: false,

      userToken: '',
      seatSelection: [],
      isSeatGridLoading: true,
      selectedSeatIds: new Map(),
      requestBookingData: {
        requestPrice: 0,
        seatSectionType: '',
        sectionMinimumPrice: 0,
        seatIds: [],
        seatNumber: [],
        walletBalance: 0,
        sectionMinimumPriceErrorMessage: false
      },
      isPriceAlertOpen: false,
      isPricePerTicketOpen: false,
      isConfirmationForPriceOpen: false,
      isRequestPlacedOpen: false,
      isInsufficientBalanceOpen: false,
      isBackDropLoadingOpen: false,
      showKycDialog: false,
      openBookingError: false,
      notifyMeChecked: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.parentRef = createRef();
    this.childRef = createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));


      if (apiRequestCallId && responseJson !== undefined) {
        this.handleAPIResponse(apiRequestCallId, responseJson)
      }
    } else {
      runEngine.debugLog("GOIT");
    }

  }

  /**
  * Handle API response
  */
  handleAPIResponse = (apiRequestCallId: string, responseJson: any) => {

    switch (apiRequestCallId) {
      case this.getScreenCalledId:
        this.setState({ seats: responseJson, isLoading: false })
        this.createSeatSelectionGrid(responseJson)
        break;

      case this.createBookingApiId:
        this.handleCreateBooking(responseJson)
        break;

      case this.walletBalanceApiId:
        this.handleWalletBalance(responseJson as IWalletResponse)
        break;

      case this.createRequestBookingApiId:
        this.handleSuccessRequestBooking();
        break;
      
      case this.createResellBookingApiId:
        this.handleCreateBooking(responseJson);
        break;

      case this.getAccountDetailsCallId:
        this.handleAccountDetails(responseJson.data);
        break;

      case this.notifyMeApiId:
        this.redirectToHomePage();
        break;

      default:
        break;

    }
  }

  getDataFromLocal = async () => {
    const screen = await getStorageData(LocalStorageKeys.SelectTimeSlotDetail, true)
    const screenName = screen?.selectedScreenDetail?.screenDetail
    const date = screen?.selectedScreenDetail?.selectedDate
    this.setState({
      date: date,
      screen: screenName,
      movieName: screen?.movieName?.name,
      movieId: +screen.movieName?.movieId,
      timeSlotList: screen?.screenTimeSlots?.timeSlots || []
    })
  }

  async componentDidMount() {
    // Customizable Area Start
    this.seatSelectionById()
    this.getDataFromLocal()
    this.handleOpenModal()
    const userDetails = await getStorageData(LocalStorageKeys.UserDetails, true) as IUserDetails
    const userToken = await getStorageData(LocalStorageKeys.LoginToken) as string 
    this.setState({ userDetails: userDetails, userToken }, () => { })
    if (this.state.seatTypeNumber[1].isSelected === true) {
      this.setState({ selectedNumber: this.state.seatTypeNumber[1].numberOfSeats })
    }
    if (userToken) {
      localStorage.setItem("isGuest", "false")
    } else {
      localStorage.setItem("isGuest", "true")

    }
    window.addEventListener(EventListenerKeys.ProfileUpdated, this.handleRequestBooking)
    // Customizable Area End
  }


  // get data by id  
  seatSelectionById = (id?: number) => {
    this.setState({ isLoading: true })
    let screenId: number
    if (id) {
      screenId = id
    } else {
      screenId = this.props.navigation.getParam("navigationBarTitleText")
    }
    let token: string = "";
    if (typeof window !== "undefined") {
      token = localStorage.getItem("login") || configJSON.guestToken
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getScreenCalledId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getShowScreenAPiEndPoint}/${screenId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
  };

  handleOpenModalEdit = () => {
    this.setState({ isEdit: true })
    this.setState({ isModalOpen: true, bookingValue: false });
  }

  handleCloseModal = () => {
    this.setState({ isModalOpen: false, isEdit: false });
  };

  closeBookingErrorDialog = () => {
    this.setState({ openBookingError: false, bookingError: '', selectedSeatIds: new Map() })
    this.seatSelectionById(this.state.screen.screenId)
  }


  createBooking = (userEmail: string, userPhoneNumber: string, loginToken: string) => {

    const { screen: { screenId }, movieId, selectedSeatIds } = this.state;
    const payload = {
      booking: {
        screening_id: screenId,
        seat_ids: Array.from(selectedSeatIds.keys()),
        number_of_seats: selectedSeatIds.size,
        bookable_id: movieId,
        bookable_type: configJSON.bookableMovieType,
        account_email: userEmail,
        full_phone_number: userPhoneNumber
      }
    }

    this.createBookingApiId = callApi({
      contentType: configJSON.contentTypeApi,
      method: configJSON.postAPiMethod,
      endPoint: configJSON.createBookingEndPoint,
      headers: { "token": loginToken },
      body: payload
    }, runEngine);
  }

  async handleCreateBooking(resJson: any) {
    const result = resJson.data as IBookingResponse
    if (resJson.data) {
      const bookingData: IBookingData = {
        id: +result.id,
        totalAmount: +result.attributes.total_amount,
        hideOtherPayment: result.attributes.is_resold_booking
      }

      await setStorageData(LocalStorageKeys.BookingData, JSON.stringify(bookingData))

      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), "FoodAndBeverages");
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
      raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {});
      msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(msg)
    }
    else {
      const error = resJson && resJson.errors
      this.setState({ bookingError: error.message, openBookingError: true })
    }
  }
  handleNavigate = async () => {

    const userDetails = await getStorageData(LocalStorageKeys.UserDetails, true) as IUserDetails
    const loginToken = await getStorageData(LocalStorageKeys.LoginToken) as string

    /**
     * Check for Valid user and if it is valid then create booking
     * or else redirect to guest page
     */
    if (userDetails && loginToken) {

      // check for regular booking
      const { selectedSeatIds } = this.state;
      const selectedSeatStatus = selectedSeatIds.get(Array.from(selectedSeatIds.keys())[0])?.seatStatus;

      if (selectedSeatStatus === SeatStatus.Available) {
        this.createBooking(userDetails.attributes.email, userDetails.attributes.full_phone_number, loginToken)
      } else if (selectedSeatStatus === SeatStatus.Buy) {
        this.createResellBooking()
      } else {
        this.handleRequestBooking()
      }
    } else {
      this.setState({ doSignUp: true })
    }

  }
  handleChangeTime = (id: number) => {
    // change the screen time id and passing in the api 
    const newScreenDetail = {
      ...this.state.screen,
      screenId: id
    };
    this.seatSelectionById(id)
    this.setState({ screen: newScreenDetail, isSeatGridLoading: true })
  };
  // function for the selecting the numbers of the seats 
  handleNumberSelect = (numberOfSeats: number) => {
    this.setState({
      selectedSeatIds: new Map(),
      selectedNumber: numberOfSeats, seatsSelected: [], seatTypeNumber: this.state.seatTypeNumber.map((item: any) => {
        if (numberOfSeats === item.numberOfSeats) {
          return { ...item, isSelected: true }
        } else {
          return { ...item, isSelected: false }

        }
      })
    })

  };

  // on close of the signup modal
  closeSignUpModal = async () => {
    const userToken = (await getStorageData(LocalStorageKeys.LoginToken)) as string
    this.setState({ doSignUp: false, isGuest: !!userToken, userToken: userToken });
  }

  handleGuest = async () => {
    const { userDetails, selectedSeatIds } = this.state
    const loginToken = await getStorageData(LocalStorageKeys.LoginToken) as string
    const isGuest = (await getStorageData("isGuest")) === "true";
    const availableSeats = selectedSeatIds.size;

    if (!isGuest && availableSeats > 0 && userDetails && loginToken) {
      this.createBooking(userDetails.attributes.email, userDetails.attributes.full_phone_number, loginToken)
    }
  }
  async componentDidUpdate(_:Props, prevState: S) {
    if(!prevState.isGuest && this.state.isGuest) {
      this.getUserDetails();
    }
    if(this.state.seatSelection && prevState.seatSelection !== this.state.seatSelection) {
      this.updateJustifyContent()
    }
    window.addEventListener(EventListenerKeys.ProfileUpdated, this.getUserDetails)
  }

  async componentWillUnmount() {
      window.removeEventListener(EventListenerKeys.ProfileUpdated, this.getUserDetails);
  };

  updateJustifyContent = () => {
    if (this.parentRef.current && this.childRef.current) {
      const parentWidth = this.parentRef.current.offsetWidth;
      const childWidth = this.childRef.current.offsetWidth;

      if (childWidth > parentWidth) {
        this.parentRef.current.style.justifyContent = 'flex-start';
      } else {
        this.parentRef.current.style.justifyContent = 'center';
      }
    }
  }

  handleAccountDetails = (userDetails: IUserDetails) => {
    setStorageData(LocalStorageKeys.UserDetails, JSON.stringify(userDetails)).then(() => {
      this.handleNavigate();
    })
  } 

  getUserDetails = () => {
    this.getAccountDetailsCallId = callApi({
      contentType: configJSON.APIContentType,
      method: configJSON.GetAPIMethod,
      endPoint: configJSON.getAccountDetailsEndPoint,
      headers: { "token": this.state.userToken }
    }, runEngine)
  }

  /**
   * Prepare data for seat selection grid
   */
  createSeatSelectionGrid(resJson: ISeatSelectionGridApiResponse) {

    // create price object with having key of seat_type for grid creation
    const sectionTypeObject: { [key: string]: IPrice } = {};
    resJson.price.forEach((price: IPrice) => {
      sectionTypeObject[price.seat_type] = price
    })

    // group seats by seat_type
    const seatsByTypeArr: {[key: string]: ISeatStructure[]} = {};
    resJson.seats.data.forEach((seat: ISeatStructure) => {
      const seatType = seat.attributes.seat_type;
      if (!seatsByTypeArr[seatType]) {
        seatsByTypeArr[seatType] = [];
      }
      seatsByTypeArr[seatType].push(seat);
    });

    // create sections and rows
    const seatSelectionArray: ISection[] = [];

    for (let seatType in seatsByTypeArr) {

      const sectionObject: ISection = {
        sectionName: seatType,
        price: sectionTypeObject[seatType].price,
        rows: []
      };

      const seatsDataArr = seatsByTypeArr[seatType].reduce((accumulator: { [key: string]: ISeatStructure[] }, seat: ISeatStructure) => {
        if ( !accumulator[seat.attributes.row] ) {
          accumulator[seat.attributes.row]=[];
        }
        accumulator[seat.attributes.row].push(seat);
        return accumulator;
      }, {});

      for (let rowLabel in seatsDataArr) {

        const seatsInRow = seatsDataArr[rowLabel].map((seat: ISeatStructure) => {
          
          return {
            seatNumber: seat.attributes.seat_number,
            id: +seat.id,
            status: this.getStatusOfSeat(seat),
            bookingId: seat.attributes.booking_id,
            seatSectionType: seat.attributes.seat_type,
            seatPrice: seat.attributes.request_price
          } as ISeat;

        });

        sectionObject.rows.push({ rowLabel, seats: seatsInRow });

      }

      seatSelectionArray.push(sectionObject);
    }

    // assign the structured data to the state
    this.setState({
      seatSelection: seatSelectionArray,
      isSeatGridLoading: false
    });
  }

  /**
   * return status of seat based on different condition
   */
  getStatusOfSeat(seat: ISeatStructure) {

    // seat is holded by other user or not 
    if (
      seat.attributes.holder_ids.length ||
      seat.attributes.account_id === +this.state.userDetails?.id ||
      seat.attributes.requester_ids.includes(+this.state.userDetails?.id) ||
      (seat.attributes.booking_id && seat.attributes.account_id && seat.attributes.user_kyc !== "approved")
    ) return SeatStatus.Unavailable
    return seat.attributes.reserved
  }

  /**
   * Logic for seat selection
   */
  handleSeatSelection(seatId: number, seatStatus: SeatStatus, seatSectionType: string, seatNumber: string, bookingId: number) {

    if (![SeatStatus.Available, SeatStatus.Buy, SeatStatus.Selected, SeatStatus.Request].includes(seatStatus)) return
    const oldSelectedValues = new Map(this.state.selectedSeatIds)

    if (SeatStatus.Available === seatStatus) {

      if (
        oldSelectedValues.size > 0 &&
        oldSelectedValues.size < this.state.selectedNumber &&
        this.belongToSameStatusAndSection(seatStatus, seatSectionType)
      ) {
        const remainingToSelect = this.state.selectedNumber - this.state.selectedSeatIds.size

        // push the code
        const seats = this.doSelectionForAvailableStatus(seatId, seatSectionType)

        const newSeats = seats.length < remainingToSelect ? seats : seats.slice(0, remainingToSelect);
        newSeats.forEach(seat => {
          oldSelectedValues.set(seat.id, { seatSectionType: seat.seatSectionType, seatStatus: seat.status, seatNumber: seat.seatNumber, bookingId })
        })
        this.setState({ selectedSeatIds: oldSelectedValues })

      } else {

        // replace the seats
        const seats = this.doSelectionForAvailableStatus(seatId, seatSectionType)
        const totalSeats = this.state.selectedNumber

        const newMap = new Map<number, ISelectedSeatData>()

        const newSeats = seats.length < totalSeats ? seats : seats.slice(0, totalSeats);
        newSeats.forEach(seat => {
          newMap.set(seat.id, { seatSectionType: seat.seatSectionType, seatStatus: seat.status, seatNumber: seat.seatNumber, bookingId })
        })
        this.setState({ selectedSeatIds: newMap })

      }

      return
    }

    // check for only Request and Buy status seats
    if (
      oldSelectedValues.size > 0 &&
      oldSelectedValues.size < this.state.selectedNumber &&
      this.belongToSameStatusAndSection(seatStatus, seatSectionType)
    ) {

      oldSelectedValues.set(seatId, { seatSectionType, seatStatus, seatNumber, bookingId })
      this.setState({ selectedSeatIds: oldSelectedValues })

    } else {

      const newMap = new Map<number, ISelectedSeatData>()
      newMap.set(seatId, { seatSectionType, seatStatus, seatNumber, bookingId })
      this.setState({ selectedSeatIds: newMap })

    }

  }

  /**
   * Check for seat selected is same status and section type
   */
  belongToSameStatusAndSection(seatStatus: SeatStatus, seatSectionType: string): boolean {

    const keys = this.state.selectedSeatIds.keys()
    const keysArray = Array.from(keys)

    const oldData = this.state.selectedSeatIds.get(keysArray[0])

    if (oldData && oldData.seatStatus === seatStatus && oldData.seatSectionType === seatSectionType) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Seat Selection logic for status having available only because it will behave differently
   */
  doSelectionForAvailableStatus(selectedId: number, seatSectionType: string): ISeat[] {

    const section = this.state.seatSelection.find(section => section.sectionName === seatSectionType);
    if (!section) return [];

    let availableSeats :any = [];
    let foundSeat = false;

    for (const seatRow of section.rows) {
      if (foundSeat) break;
      this.checked(seatRow, foundSeat, availableSeats, selectedId)
    }

    return availableSeats;
  }

  checked = (row:any, foundSeat:any, availableSeats:any, selectedId:any) =>{
    for (const seat of row.seats) {
      if (foundSeat) {
        if (seat.status === 'available') availableSeats.push(seat);
        else break;
      }
      if (seat.id === selectedId) {
        foundSeat = true;
        availableSeats.push(seat);
      }
    }
  }

  /**
   * handle requested booking 
   */
  async handleRequestBooking() {

    // check user is KYC verified or not
    const userDetails = (await getStorageData(LocalStorageKeys.UserDetails, true)) as IUserDetails;
    if(userDetails.attributes.kyc_status != "approved") {
      this.setState({ showKycDialog: true });
      return
    }

    const { selectedSeatIds, seatSelection } = this.state;

    const seatIds = Array.from(selectedSeatIds.keys());
    const seatSectionType = selectedSeatIds.get(seatIds[0])?.seatSectionType;

    const filteredSection = seatSelection.find(section => section.sectionName === seatSectionType)

    const seatNumber: string[] = []
    selectedSeatIds.forEach(value => {
      seatNumber.push(value.seatNumber)
    })

    const { price: sectionMinimumPrice } = filteredSection as ISection

    this.setState({
      requestBookingData: {
        sectionMinimumPrice,
        requestPrice: sectionMinimumPrice,
        seatSectionType: seatSectionType ?? '',
        seatIds,
        seatNumber,
        walletBalance: 0,
        sectionMinimumPriceErrorMessage: false
      },
      isPriceAlertOpen: true
    })

  }

  /**
   * Dialog logic for Request flow
   */

  handleClosePriceAlert = () => this.setState({ isPriceAlertOpen: false })
  handleClosePricePerTicket = () => this.setState({ isPricePerTicketOpen: false })
  handleCloseConfirmationPrice = () => this.setState({ isConfirmationForPriceOpen: false })
  handleCloseInsufficientBalance = () => this.setState({ isInsufficientBalanceOpen: false })
  handleKycClose = () => this.setState({ showKycDialog: false })

  acceptPriceAlert = () => {
    this.setState({
      isPriceAlertOpen: false,
      isBackDropLoadingOpen: true,
    })

    this.walletBalanceApiId = callApi({
      contentType: configJSON.APIContentType,
      method: configJSON.GetAPIMethod,
      endPoint: configJSON.GetWalletBalanceApiEndPoint,
      headers: { "token": this.state.userToken }
    }, runEngine)

  }

  handleWalletBalance = (responseJson: IWalletResponse) => {
    this.setState((prevState) => ({
      isBackDropLoadingOpen: false,
      isPricePerTicketOpen: true,
      requestBookingData: {
        ...prevState.requestBookingData,
        walletBalance: responseJson.total_amount
      }
    }))
  }

  sendRequest = () => {

    const { requestPrice, seatIds, walletBalance } = this.state.requestBookingData;
    let reuiredAmount = requestPrice * seatIds.length;

    // if user has sufficient wallet or not
    if (walletBalance > reuiredAmount) {

      this.setState({
        isPricePerTicketOpen: false,
        isConfirmationForPriceOpen: true
      })

    } else {
      this.setState({ isPricePerTicketOpen: false, isInsufficientBalanceOpen: true })
    }

  }

  changePricePerTicket(value: string) {
    if (/^\d*$/.test(value)) {
      this.setState((prevState) => ({
        requestBookingData: {
          ...prevState.requestBookingData,
          requestPrice: +value,
          sectionMinimumPriceErrorMessage: +value < prevState.requestBookingData.sectionMinimumPrice
        }
      }))
    }
  }

  confirmRequest = () => {
    this.setState({
      isConfirmationForPriceOpen: false,
      isBackDropLoadingOpen: true
    })

    // API call for Request

    const bookings: { booking_id: number, ticket_price: number, seat_ids: number[] }[] = [];
    const seatIdsMap: Map<number, number[]> = new Map();

    // Organize seats based on booking ID
    for (const [key, value] of this.state.selectedSeatIds) {
      const { bookingId } = value;
      if (seatIdsMap.has(bookingId)) {
        seatIdsMap.get(bookingId)?.push(key);
      } else {
        seatIdsMap.set(bookingId, [key]);
      }
    }

    // Create bookings array for payload
    for (const [bookingId, seatIds] of seatIdsMap) {
      const booking = {
        booking_id: bookingId,
        ticket_price: this.state.requestBookingData.requestPrice,
        seat_ids: seatIds
      };
      bookings.push(booking);
    }

    // Assemble payload
    const payload: IRequestBookingPayload = {
      booking_request: {
        bookings: bookings,
        request_type: "buy",
        screening_id: this.state.screen.screenId
      }
    };

    // API call for Request Booking
    this.createRequestBookingApiId = callApi({
      contentType: configJSON.APIContentType,
      method: configJSON.PostAPIMethod,
      endPoint: configJSON.CreateRequestBookingApiEndPoint,
      headers: { "token": this.state.userToken },
      body: payload
    }, runEngine)


  }
  
  /**
   * create resell booking ticket 
   */
  createResellBooking() {

    this.setState({ isBackDropLoadingOpen: true });

    const seatIdsMap = new Set<number>();
    const bookingIdsMap = new Set<number>();

    // filterout the booking id and seat is for payload
    for (const [seatKey, value] of this.state.selectedSeatIds) {
      const { bookingId } = value
      if(!seatIdsMap.has(+seatKey)) seatIdsMap.add(+seatKey) 
      if(!bookingIdsMap.has(bookingId)) bookingIdsMap.add(bookingId) 
    }

    // generate payload for resell tickets booking 
    const resellTicketPayload = {
      seat_ids: [...seatIdsMap],
      booking_ids: [...bookingIdsMap],
      screening_id: this.state.screen.screenId
    }

    // API call for Resell Ticket Booking
    this.createResellBookingApiId = callApi({
      contentType: configJSON.APIContentType,
      method: configJSON.PutAPIMethod,
      endPoint: configJSON.ResellRequestBookingApiEndPoint,
      headers: { "token": this.state.userToken },
      body: resellTicketPayload
    }, runEngine)

  }

  handleEditPricePerTicket = () => {
    this.setState({
      isConfirmationForPriceOpen: false,
      isPricePerTicketOpen: true
    })
  }

  handleSuccessRequestBooking = () => {
    this.setState({ isBackDropLoadingOpen: false, isRequestPlacedOpen: true })
  }

  completeRequestAndNavigate = () => {

    const { notifyMeChecked } = this.state;

    if(notifyMeChecked) {
      this.setState({ isRequestPlacedOpen: false, isBackDropLoadingOpen: true });

      const notifyPayload = {
        screening_id: this.state.screen.screenId,
        seat_type: this.state.requestBookingData.seatSectionType
      }


      this.notifyMeApiId = callApi({
        contentType: configJSON.APIContentType,
        method: configJSON.PostAPIMethod,
        endPoint: configJSON.notifyMeApiEndPoint,
        headers: { "token": this.state.userToken },
        body: notifyPayload
      }, runEngine)


    } else {
      this.redirectToHomePage()
    }

  }

  redirectToHomePage = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Homepage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  redirectToWalletScreen = async () => {
    let seatNumber = getLastPartOfURL()
    let pathName : string= `SeatSelection/${seatNumber}`
    await setStorageData(LocalStorageKeys.RedirectTo, pathName);
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "AddMoney");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), "Wallet");
    this.send(message);
  }

  getChipClassName = (screenId: number): "default" | "outlined" => screenId === this.state.screen?.screenId ? "default" : "outlined"
  checkForDisabledButton = (): boolean => (this.state.isLoading || (this.state.selectedNumber !== this.state.selectedSeatIds.size))
  getButtonClassName = (numberOfSeats: number): "contained" | "outlined" => numberOfSeats === this.state.selectedNumber ? "contained" : "outlined"

  handleNotifyMeCheck: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    this.setState({ notifyMeChecked: event.target.checked })
  }
}
