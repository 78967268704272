import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { LocalStorageKeys } from "../../../components/src/enums.web";
import { callApi } from "../../../components/src/Toolkit";
import { IPagination, ITransaction, ITransactionResponse } from "../../../components/src/interfaces.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userToken: string
  isLoading: boolean
  transactionList: ITransaction[]
  meta: IPagination,
  errorMessage: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TransactionHistoryController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTransactionsApiId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      userToken: '',
      isLoading: true,
      transactionList: [],
      meta: {} as IPagination,
      errorMessage: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const resJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId && resJson !== undefined && apiRequestCallId === this.getTransactionsApiId) {
        if(resJson?.errors) {
          this.setState({
            transactionList: [],
            isLoading: false,
            errorMessage: resJson.errors[0]?.message || ""
          })
          return;
        }
        this.setState({
          transactionList: (resJson as ITransactionResponse).data || [],
          meta: (resJson as ITransactionResponse).meta_data.pagination,
          isLoading: false,
          errorMessage: ""
        })
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  getTransactions = (page?: number | null) => {

    let perPageTransaction = 10;

    let endpointurl = `${configJSON.getTransactionHistoryEndPoint}?all_transactions=true&per=${perPageTransaction}`

    if(page) endpointurl = endpointurl.concat(`&page=${page}`)

    this.getTransactionsApiId = callApi({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: endpointurl,
      headers: { "token": this.state.userToken },
    }, runEngine)

  }
  
  async componentDidMount() {
    window.scrollTo(0, 0);
    const userToken = await getStorageData(LocalStorageKeys.LoginToken) as string;
    this.setState({ userToken }, () => this.getTransactions())
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }
  // Customizable Area End
}
